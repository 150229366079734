
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions: GridOptions = {
      stripe: false,
      title: "抽獎類別",
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "抽獎類別清單",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "抽獎類別清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          },
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "filter-name" }
        },
        {
          field: "Published",
          title: "已發佈",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => (cellValue ? "是" : "否")
        },
        {
          field: "Ordinal",
          title: "排序",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        query: model
          ? params => {
              if (params.condition)
                params.condition.and("ParentId", Operator.IsNull);
              else
                params.condition = new Condition("ParentId", Operator.IsNull);
              return model.dispatch("category/query", params);
            }
          : undefined,
        queryAll: model ? () => model.dispatch("category/query") : undefined,
        save: model
          ? params => model.dispatch("category/save", params)
          : undefined
      },
      modalConfig: { showFooter: true },
      treeConfig: { children: "Children" }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 60,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "Published",
          title: "已發佈",
          span: 12,
          titleWidth: 60,
          itemRender: {
            name: "$select",
            options: [
              { value: false, label: "否" },
              { value: true, label: "是" }
            ]
          }
        },
        {
          field: "Ordinal",
          title: "排序",
          span: 12,
          titleWidth: 60,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" }
          }
        }
        // {
        //   field: "ParentId",
        //   title: "父類別",
        //   span: 24,
        //   titleWidth: 60,
        //   slots: { default: "column-parent-id" }
        // }
      ],
      rules: {
        Name: [{ type: "string", required: true }]
      }
    };

    // const parentIdSelectOptions: SelectBoxOptions = {
    //   showSearch: true,
    //   rowId: "Id",
    //   placeholder: "選擇分類",
    //   textField: "Name",
    //   valueField: "Id",
    //   formatText: async (row: any) => {
    //     let entity = await model!.dispatch("category/find", row.Id); // eslint-disable-line
    //     let text = entity.Name;
    //     while (entity.Parent) {
    //       entity = entity.Parent;
    //       text = `${entity.Name}>${text}`;
    //     }
    //     return text;
    //   },
    //   columns: [
    //     {
    //       field: "Name",
    //       title: "名稱",
    //       showHeaderOverflow: true,
    //       showOverflow: true,
    //       sortable: true,
    //       treeNode: true
    //     }
    //   ],
    //   promises: {
    //     find: value => model!.dispatch("category/find", value), // eslint-disable-line
    //     query: params => {
    //       if (params.condition)
    //         params.condition.and("ParentId", Operator.IsNull);
    //       else params.condition = new Condition("ParentId", Operator.IsNull);
    //       return model!.dispatch("category/query", params); // eslint-disable-line
    //     }
    //   },
    //   treeConfig: { parent: "ParentId", children: "Children" }
    // };

    return {
      grid,
      gridOptions,
      formOptions
      // parentIdSelectOptions
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    }
  }
});
